<template>
 <CCard>
  <CCardBody>


    <CDataTable :items="items"  
    :fields="fields"
     striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       
      pagination>
        <template #index="data">
       <td>
         {{data.index +1}}
      </td>
     </template>
       <template #fieldValue="{item}">
        <td class='font-weight-bold'
          v-if="item.fieldValue"
        >{{ item.fieldValue }}</td>
        <td v-else>--</td>
      </template>

      </CDataTable>
  </CCardBody>
   <div ref="modalArea"></div>
   <CCardFooter align="right">

            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
 </CCard>
</template>

<script>
const fields = [
   { key: "index", _classes: 'font-weight-bold', _style: "min-width:200px" },
     { key: "inputName", label: "Field Name",  _classes: 'font-weight-bold', _style: "min-width:200px" },
 { key: "fieldValue", label: "Field Value", _classes: 'font-weight-bold', _style: "min-width:200px" },

];
import Vue from "vue";
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "InputFieldValue",
  components: {
     ConfirmationModal,
   },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getInputFieldValues() {
       this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/section/input/values/"+this.$route.params.sectionFormId)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
cancel() {
   
           window.history.back();
    },
    navToNewSymptoms(){
       this.$router.push({ name: "InputFieldValue", params: { mode: "new" } });
    }
  },
  mounted() {
    this.getInputFieldValues();
  },
};
</script>